import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login";
import { AuthProvider } from "./context/AuthProvider";

import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import Dashboard from "./pages/Dashboard";
import PrivateRoute from "./routes/PrivateRoute";
import Test from "./pages/Test";
import Partners from "./pages/Partners";
import PartnerMaster from "./pages/PartnerMaster";
import SpecialEventMaster from "./pages/SpecialEventMaster";
import Manufactures from "./pages/Manufactures";
import SpecialEvents from "./pages/SpecialEvents";
import BreifAbout from "./pages/LandingPage/BreifAbout";
import Advertising from "./pages/LandingPage/Advertising";
import BestSellers from "./pages/LandingPage/BestSellers";
import Awards from "./pages/LandingPage/Awards";
import Testimonial from "./pages/LandingPage/Testimonial";

const AppContent = () => {
  const location = useLocation();

  const showHeaderAndSidebar =
    location.pathname !== "/login" &&
    location.pathname !== "/";

  const WithSidebarLayout = ({ children }) => (
    <div class="container-scroller">
      <Sidebar />
      <div className="navpad">
      <div class="container-fluid page-body-wrapper">
        <Navbar />
        <div class="main-panel">
          <div class="content-wrapper">{children}</div>
        </div>
      </div>
    </div>
    </div >
  );

const WithoutSidebarLayout = ({ children }) => <div>{children}</div>;

return (
  <>
    {showHeaderAndSidebar ? (
      <WithSidebarLayout>
        <Routes>
          <Route
            path="/dashboard"
            element={<PrivateRoute element={Dashboard} />}
          />
          <Route
            path="/breif_about"
            element={<PrivateRoute element={BreifAbout} />}
          />
          <Route
            path="/advertising"
            element={<PrivateRoute element={Advertising} />}
          />
          <Route
            path="/best_sellers"
            element={<PrivateRoute element={BestSellers} />}
          />
          <Route
            path="/awards"
            element={<PrivateRoute element={Awards} />}
          />
          <Route
            path="/testimonial"
            element={<PrivateRoute element={Testimonial} />}
          />
          <Route
            path="/test"
            element={<PrivateRoute element={Test} />}
          />
          <Route
            path="/partners"
            element={<PrivateRoute element={Partners} />}
          />
           <Route
            path="/manufactures"
            element={<PrivateRoute element={Manufactures} />}
          />
          <Route
            path="/special-events"
            element={<PrivateRoute element={SpecialEvents} />}
          />
           <Route
            path="/partner-master"
            element={<PrivateRoute element={PartnerMaster} />}
          />
          <Route
            path="/event-master"
            element={<PrivateRoute element={SpecialEventMaster} />}
          />
        </Routes>
      </WithSidebarLayout>
    ) : (
      <WithoutSidebarLayout>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </WithoutSidebarLayout>
    )}
  </>
);
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
