import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Add styles
import { createBestSellers, createTestimonial, fetchBestSellers, fetchTestimonial, selectAllHomeInfo, updateBestSellers, updateTestimonial } from '../../app/features/homeSlice';

const TestimonialForm = ({ handleClose, initialValues }) => {
    const homeinfo = useSelector(selectAllHomeInfo);

    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        position: '',
        content: '',
        isActive: '',
        imageUrl: null,
        bgImageUrl: null
    });

    const [imagePreview, setImagePreview] = useState('');
    const [bgImagePreview, setBgImagePreview] = useState('');
    const [fileInputKey, setFileInputKey] = useState(Date.now());

    useEffect(() => {
        if (initialValues) {
            setFormData({
                id: initialValues.id,
                position: initialValues.position,
                name: initialValues.name || '',
                content: initialValues.content || '',
                isActive: initialValues.is_active === 1 ? 'publish' : 'unpublish' || '',
                imageUrl: null,
                bgImageUrl: null
            });

            if (initialValues.imageUrl) {
                setImagePreview(initialValues.imageUrl);
            }

            if (initialValues.bgImageUrl) {
                setBgImagePreview(initialValues.bgImageUrl);
            }
        } else {
            setFormData({
                name: '',
                position: '',
                content: '',
                isActive: '',
                imageUrl: null,
                bgImageUrl: null
            });
            setImagePreview('');
            setBgImagePreview('');
        }
    }, [initialValues]);

    const handleChange = (e) => {
        const { name, type, files } = e.target;

        if (type === 'file') {
            if (files.length > 0) {
                if (name === 'imageUrl') {
                    setFormData({
                        ...formData,
                        imageUrl: files[0],
                    });
                    setImagePreview(URL.createObjectURL(files[0]));
                } else if (name === 'bgImageUrl') {
                    setFormData({
                        ...formData,
                        bgImageUrl: files[0],
                    });
                    setBgImagePreview(URL.createObjectURL(files[0])); // Update the background image preview
                }
            } else {
                if (name === 'imageUrl') {
                    setFormData({
                        ...formData,
                        imageUrl: null,
                    });
                    setImagePreview(null);
                } else if (name === 'bgImageUrl') {
                    setFormData({
                        ...formData,
                        bgImageUrl: null,
                    });
                    setBgImagePreview(null);
                }
            }
        } else {
            setFormData({
                ...formData,
                [name]: e.target.value,
            });
        }
    };

    const handleQuillChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            content: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const action = initialValues?.id ? updateTestimonial(formData) : createTestimonial(formData);
        dispatch(action)
            .unwrap()
            .then((response) => {
                setFormData({
                    name: '',
                    position: '',
                    content: '',
                    isActive: '',
                    imageUrl: null,
                    bgImageUrl: null
                });
                setImagePreview('');
                setBgImagePreview('');
                setFileInputKey(Date.now());
                dispatch(fetchTestimonial({ page: 1, type: '', search_text: '' }));
                toast.success(response.message);
                handleClose();
            })
            .catch((error) => {
                toast.error(error.error || 'Failed to create service');
            });
    };


    const modules = {
        toolbar: [
            // [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            [{ 'align': [] }],
            [{ 'color': ['#cbd62e', 'white', 'black'] }, { 'background': [] }], // Add color and background options
            ['clean']                                         // Remove formatting button
        ],
    };

    const formats = [
        // 'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'align', 'color', 'background' // Add color and background formats
    ];
    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="title" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='col'>
                        <label htmlFor="position" className="form-label">position</label>
                        <input
                            type="text"
                            className="form-control"
                            id="position"
                            name="position"
                            value={formData.position}
                            onChange={handleChange}
                            required
                        />
                    </div>

                </div>
            </div>
            <div className='mb-3'>
                <label htmlFor="content" className="form-label">Content</label>
                <ReactQuill
                    className='bg-white text-dark'
                    value={formData.content}
                    onChange={handleQuillChange}
                    modules={modules}
                    formats={formats}
                />
            </div>
            <div className="mb-3">
                <div className='row'>
                    <div className='col-6'>
                        <label htmlFor="imageUrl" className="form-label">Profile Image</label>
                        <input
                            type="file"
                            className="form-control"
                            id="imageUrl"
                            name="imageUrl"
                            onChange={handleChange}
                            key={fileInputKey}
                        />
                        <small className="form-text small text-muted">
                            Required resolution: 350 x 400
                        </small>
                    </div>
                    <div className='col-6'>
                        <label htmlFor="bgImageUrl" className="form-label">Background Image</label>
                        <input
                            type="file"
                            className="form-control"
                            id="bgImageUrl"
                            name="bgImageUrl"
                            onChange={handleChange}
                            key={fileInputKey}
                        />
                        <small className="form-text small text-muted">
                            Required resolution: 1750 x 600
                        </small>

                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className='row'>


                    {imagePreview && (
                        <div className='col mt-2'>
                            <img
                                src={imagePreview}
                                alt="Profile"
                                style={{ width: '100px', height: 'auto' }}
                            />
                        </div>
                    )}
                    {bgImagePreview && (
                        <div className='col mt-2'>
                            <img
                                src={bgImagePreview}
                                alt="Background"
                                style={{ width: '100px', height: 'auto' }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className='mb-3'>
                <div className='row'>
                    <div className='col-6'>
                        <label htmlFor="isActive" className="form-label">Status</label>
                        <select
                            className="form-select form-control text-white"
                            id="isActive"
                            name="isActive"
                            value={formData.isActive}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select status</option>
                            <option value="publish">Publish</option>
                            <option value="unpublish">Unpublish</option>
                        </select>
                    </div>
                </div>
            </div>

            <button type="submit" className="btn text-dark" style={{ backgroundColor: '#cbd62e' }}>
                {
                    homeinfo.loading ? (
                        <>
                            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                            Creating Testimonial
                        </>
                    ) : <>
                    Submit
                    </>
                }
            </button>
        </form>
    );
};

export default TestimonialForm;
