import React, { useState, useEffect } from "react";
import Logo from '../assets/images/znLogo.png';
import StampLogo from '../assets/images/stamp_logo.png';
import { Link, useLocation } from "react-router-dom";

function Sidebar() {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    const navbarCollapse = document.querySelector('.navbar-collapse');
    if (navbarCollapse) {
      navbarCollapse.classList.remove('show');
    }
  }, [location.pathname]);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <>
      <nav className="sidebar sidebar-offcanvas" id="sidebar" style={{ position: 'fixed', left: 0, top: 0 }}>
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo text-center" href="index.html">
            <img src={Logo} alt="logo" />
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img src={StampLogo} alt="logo" />
          </a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-category">
            <span className="nav-link">Navigation</span>
          </li>
          <li className={`nav-item menu-items ${isActive('/dashboard') ? 'active' : ''}`}>
            <Link to="/dashboard" className="nav-link">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          <li className={`nav-item menu-items ${isActive('/breif_about') || isActive('/advertising') || isActive('/best_sellers') ? 'active' : ''}`}>
        <a
          className="nav-link"
          onClick={() => toggleDropdown('home')}
          aria-expanded={openDropdown === 'home'}
          aria-controls="dropdown-home"
          style={{cursor:'pointer'}}
        >
          <span className="menu-icon">
            <i className="mdi mdi-home"></i>
          </span>
          <span className="menu-title">Zielseite</span>
          <i className="menu-arrow"></i>
        </a>
        <div className={`collapse ${openDropdown === 'home' || isActive('/breif_about') || isActive('/advertising') || isActive('/best_sellers') || isActive('/awards') || isActive('/testimonial') ? 'show' : ''}`} id="dropdown-home">
          <ul className="nav flex-column sub-menu">
            <li className="nav-item">
              <Link to="/breif_about" className="nav-link">
                <span className="menu-icon">
                  <i className="mdi mdi-firebase"></i>
                </span> kurz darüber
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/advertising" className="nav-link">
                <span className="menu-icon">
                  <i className="mdi mdi-home-assistant"></i>
                </span> Werbung
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/best_sellers" className="nav-link">
                <span className="menu-icon">
                  <i className="mdi mdi-wechat"></i>
                </span> Best Sellers
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/awards" className="nav-link">
                <span className="menu-icon">
                  <i className="mdi mdi-ticket"></i>
                </span> Awards
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/testimonial" className="nav-link">
                <span className="menu-icon">
                  <i className="mdi mdi-collage"></i>
                </span> Testimonial
              </Link>
            </li>
          </ul>
        </div>
      </li>

          {/* <hr style={{ margin: '2px 0', border: '1px solid grey' }} /> */}

          <li className={`nav-item menu-items ${isActive('/partners') ? 'active' : ''}`}>
            <Link to="/partners" className="nav-link">
              <span className="menu-icon">
                <i className="mdi mdi-account-group-outline"></i>
              </span>
              <span className="menu-title">Partner</span>
            </Link>
          </li>

          <li className={`nav-item menu-items ${isActive('/manufactures') ? 'active' : ''}`}>
            <Link to="/manufactures" className="nav-link">
              <span className="menu-icon">
                <i className="mdi mdi-factory"></i>
              </span>
              <span className="menu-title">Nachhaltigkeitsmanufaktur</span>
            </Link>
          </li>

          <li className={`nav-item menu-items ${isActive('/special-events') ? 'active' : ''}`}>
            <Link to="/special-events" className="nav-link">
              <span className="menu-icon">
                <i className="mdi mdi-calendar-star"></i>
              </span>
              <span className="menu-title">Besondere Ereignisse</span>
            </Link>
          </li>

          {/* <hr style={{ margin: '2px 0', border: '1px solid grey' }} /> */}

          <li className={`nav-item menu-items ${isActive('/partner-master') || isActive('/event-master') ? 'active' : ''}`}>
            <a
              className="nav-link"
              onClick={() => toggleDropdown('master')}
              aria-expanded={openDropdown === 'master'}
              aria-controls="dropdown-master"
              style={{cursor:'pointer'}}

            >
              <span className="menu-icon">
                <i className="mdi mdi-cogs"></i>
              </span>
              <span className="menu-title">Meister</span>
              <i className="menu-arrow"></i>
            </a>
            <div className={`collapse ${openDropdown === 'master' || isActive('/partner-master') || isActive('/event-master') ? 'show' : ''}`} id="dropdown-master">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link to="/partner-master" className="nav-link">
                    <span className="menu-icon">
                      <i className="mdi mdi-format-list-bulleted-type"></i>
                    </span> Typ des Partners
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/event-master" className="nav-link">
                    <span className="menu-icon">
                      <i className="mdi mdi-format-list-bulleted-type"></i>
                    </span> Typ des Ereignis
                  </Link>
                </li>
              </ul>
            </div>
          </li>



          {/* <hr style={{ margin: '2px 0', border: '1px solid grey' }} /> */}

          {/* <li className={`nav-item menu-items ${isActive('/new') ? 'active' : ''}`}>
            <a
              className="nav-link"
              onClick={() => toggleDropdown('ui-basic2')}
              aria-expanded={openDropdown === 'ui-basic2'}
              aria-controls="ui-basic2"
            >
              <span className="menu-icon">
                <i className="mdi mdi-cogs"></i>
              </span>
              <span className="menu-title">Services</span>
              <i className="menu-arrow"></i>
            </a>
            <div className={`collapse ${openDropdown === 'ui-basic2' ? 'show' : ''}`} id="ui-basic2">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link to="/new" className="nav-link">
                    <span className="menu-icon">
                      <i className="mdi mdi-format-list-bulleted-type"></i>
                    </span> Partner's type
                  </Link>
                </li>
              </ul>
            </div>
          </li> */}
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
