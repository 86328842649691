import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createInvestor, createPartner, fetchPartners, updateInvestor, updatePartner } from '../app/features/partnersSlice';
import { fetchpartnersTypes } from '../app/features/partnersTypesSlice';
import { fetcheventsTypes } from '../app/features/eventsTypesSlice';
import { createEvent, fetchEvents, updateEvent } from '../app/features/eventsSlice';

const EventsForm = ({ handleClose, initialValues }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        specialEventType: '',
        isActive: '',
        imageUrl: null
    });

    const [imagePreview, setImagePreview] = useState('');
    const [fileInputKey, setFileInputKey] = useState(Date.now()); // Unique key for file input
    const [partnersType, setPartnersType] = useState([]);

    useEffect(() => {
        dispatch(fetcheventsTypes())
            .unwrap()
            .then((data) => {
                setPartnersType(data);
            })
            .catch(() => {
                toast.error('Failed to fetch event services');
            });
    }, [dispatch]);

    useEffect(() => {
        if (initialValues) {
            setFormData({
                id: initialValues.id,
                title: initialValues.title || '',
                description: initialValues.description || '',
                specialEventType: initialValues.specialEventType || '',
                isActive: initialValues.is_active === 1 ? 'publish' : 'unpublish' || '',
                imageUrl: null
            });

            if (initialValues.imageUrl) {
                setImagePreview(initialValues.imageUrl);
            }
        } else {
            setFormData({
                title: '',
                description: '',
                specialEventType: '',
                isActive: '',
                imageUrl: null
            });
            setImagePreview('');
        }
    }, [initialValues]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            if (files.length > 0) {
                // If a new file is selected, update the formData and image preview
                setFormData({
                    ...formData,
                    [name]: files[0],
                });
                setImagePreview(URL.createObjectURL(files[0]));
            } else {
                // If no file is selected, set the corresponding field to null
                setFormData({
                    ...formData,
                    [name]: null,
                });
                setImagePreview(null);
            }
        } else {
            // For other input fields, update normally
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        const action = initialValues?.id ? updateEvent(formData) : createEvent(formData);
        dispatch(action)
            .unwrap()
            .then((response) => {
                setFormData({
                    title: '',
                    description: '',
                    specialEventType: '',
                    isActive: '',
                    imageUrl: null
                });
                setImagePreview(''); // Clear the image preview
                setFileInputKey(Date.now()); // Reset the file input field
                dispatch(fetchEvents({ page: 1, type: '', search_text: '' }));
                toast.success(response.message);
                handleClose();
            })
            .catch((error) => {
                toast.error(error.error || 'Failed to create service');
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="title" className="form-label">Event Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='col'>
                        <label htmlFor="slug" className="form-label">Event Type</label>
                        <select
                            className="form-select form-control text-white"
                            id="specialEventType"
                            name="specialEventType"
                            value={formData.specialEventType}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select partner type</option>
                            {partnersType?.data?.map((partner) => (
                                <option key={partner.id} value={partner.id}>{partner.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div className='mb-3'>
                <label htmlFor="description" className="form-label">Description</label>
                <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    rows="10"
                    style={{ resize: 'none' }}
                    onInput={(e) => {
                        e.target.style.height = 'auto';
                        e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                    
                />
            </div>
            <div className="mb-3">
                <div className='row'>
                    <div className='col-6'>
                        <label htmlFor="imageUrl" className="form-label">Image</label>
                        <input
                            type="file"
                            className="form-control"
                            id="imageUrl"
                            name="imageUrl"
                            onChange={handleChange}
                            key={fileInputKey}
                        />
                    </div>
                    <div className='col-6'>
                        <label htmlFor="isActive" className="form-label">Status</label>
                        <select
                            className="form-select form-control text-white"
                            id="isActive"
                            name="isActive"
                            value={formData.isActive}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select status</option>
                            <option value="publish">Publish</option>
                            <option value="unpublish">Unpublish</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                {imagePreview && (
                    <div className='mt-2'>
                        <img
                            src={imagePreview}
                            alt="Selected"
                            style={{ width: '100px', height: 'auto' }}
                        />
                    </div>
                )}
            </div>
            <button type="submit" className="btn text-dark" style={{ backgroundColor: '#cbd62e' }}>Submit</button>
        </form>
    );
};

export default EventsForm;
