import React, { useState } from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css'; // Optional: for default styling

function Dashboard() {
  const [date, setDate] = useState(new Date());

  return (
    <>
      <div style={{ padding: "20px" }}>
        <h1>Willkommen in Ihrem Dashboard!</h1>
        <h3>Heute ist {date.toDateString()}</h3>

        <div style={{ marginTop: "20px" }}>
          <Calendar
            onChange={setDate}
            value={date}
          />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
