import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import { loginUser } from "../utils/api";
import StampLogo from '../assets/images/stamp_logo.png';
// import { FaEye, FaEyeSlash } from "react-icons/fa";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await loginUser(email, password);
      console.log('Login successful:', response);
      login(response.bearer_token);
      navigate('/dashboard');
    } catch (error) {
      console.error('Login failed:', error);
      setError(error.error || 'Login failed. Please try again.');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {/* <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="row w-100 m-0">
            <div className="d-flex align-items-center auth login-bg">
              <div className="card col-lg-4 mx-auto">
                <div className="card-body px-5 py-5 pt-0">
                  <div className="text-center">
                    <img src={StampLogo} className="pb-4" alt="logo" style={{width:'23%'}} />
                    <h3 className="card-title mb-3">Admin Login</h3>
                  </div>
                  <form onSubmit={handleLogin}>
                    <div className="form-group">
                      <label className="text-white">Username or email *</label>
                      <input
                        type="text"
                        className="form-control p_input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group position-relative">
                      <label className="text-white">Password *</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control p_input"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        className="position-absolute"
                        style={{
                          top: "50%",
                          right: "10px",
                          // transform: "translateY(-50%)",
                          cursor: "pointer"
                        }}
                        onClick={toggleShowPassword}
                      >
                        {showPassword ? <i class="mdi mdi-eye text-white"></i>
                          : <i className="mdi mdi-eye-off-outline text-white"></i>}
                      </span>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn pl-5 pr-5 p-3"
                        style={{ backgroundColor: '#c7d700', color: 'black' }}
                      >
                        Login
                      </button>
                    </div>
                    {error && <div className="text-danger text-center mt-3">{error}</div>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      <div className="d-flex align-items-center auth login-bg" style={{ height: '100vh' }}>
        <div className="card col-lg-4 mx-auto">
          <div className="card-body px-5 py-5 pt-0">
            <div className="text-center">
              <img src={StampLogo} className="pb-4" alt="logo" style={{ width: '23%' }} />
              <h3 className="card-title mb-3">Admin Login</h3>
            </div>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label className="text-white">Username or email *</label>
                <input
                  type="text"
                  className="form-control p_input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group position-relative">
                <label className="text-white">Password *</label>
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control p_input"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <span
                  className="position-absolute"
                  style={{
                    top: "50%",
                    right: "10px",
                    // transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                  onClick={toggleShowPassword}
                >
                  {showPassword ? <i class="mdi mdi-eye text-white"></i>
                    : <i className="mdi mdi-eye-off-outline text-white"></i>}
                </span>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  className="btn pl-5 pr-5 p-3"
                  style={{ backgroundColor: '#c7d700', color: 'black' }}
                >
                  Login
                </button>
              </div>
              {error && <div className="text-danger text-center mt-3">{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
