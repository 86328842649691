// src/features/blog/manufacturesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, jsonapiClient } from '../../utils/api';

//initial state
const initialState = {
    breif_info: [],
    advertising: [],
    best_sellers:[],
    awards:[],
    testimonials:[],
    loading: true,
    archiveLoading: true,
    res: null
};

// thunks for asynchronous actions
export const fetchBreifAbout = createAsyncThunk('home/fetchBreifAbout', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('admin/brief_about/list', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const createBreifAbout = createAsyncThunk('home/createBreifAbout', async (partnerData, { rejectWithValue }) => {
    try {
        const response = await jsonapiClient.post('admin/brief_about/create', partnerData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updateBreifAbout = createAsyncThunk('home/updateBreifAbout', async (manufactureData, { rejectWithValue }) => {
    // console.log(manufactureData)
    try {
        const { id, ...dataWithoutId } = manufactureData;
        // Log the data without the ID
        // console.log('updateService', manufactureData);
        const response = await jsonapiClient.post(`/admin//brief_about/update/${manufactureData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const archiveBreifAbout = createAsyncThunk('home/archiveBreifAbout', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_delete === 1 ? 'unarchive' : 'archive';

        console.log('datataaa', partnerData)

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/brief_about/toggle-archive/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publishBreifAbout = createAsyncThunk('home/publishBreifAbout', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_active === 1 ? 'unpublish' : 'publish';

        const response = await jsonapiClient.post(`/admin/brief_about/toggle-publish/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const deleteBreifAbout = createAsyncThunk('home/deleteBreifAbout', async (partnerId) => {
    await apiClient.delete(`/admin/brief_about/delete/${partnerId}`);
    return partnerId;
});

export const fetchAdvertising = createAsyncThunk('home/fetchAdvertising', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/advertising/list', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const createAdvertising = createAsyncThunk('home/createAdvertising', async (partnerData, { rejectWithValue }) => {
    try {
        const response = await jsonapiClient.post('/admin/advertising/create', partnerData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updateAdvertising = createAsyncThunk('home/updateAdvertising', async (data, { rejectWithValue }) => {
    // console.log(manufactureData)
    try {
        const { id, ...dataWithoutId } = data;
        // Log the data without the ID
        // console.log('updateService', manufactureData);
        const response = await jsonapiClient.post(`/admin/advertising/update/${data.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const archiveAdvertising = createAsyncThunk('home/archiveAdvertising', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_delete === 1 ? 'unarchive' : 'archive';

        console.log('datataaa', partnerData)

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/advertising/toggle-archive/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publishAdvertising = createAsyncThunk('home/publishAdvertising', async (data, { rejectWithValue }) => {
    try {
        const action = data.is_active === 1 ? 'unpublish' : 'publish';

        const response = await jsonapiClient.post(`/admin/advertising/toggle-publish/${data.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const deleteAdvertising = createAsyncThunk('home/deleteAdvertising', async (partnerId) => {
    await apiClient.delete(`/admin/advertising/delete/${partnerId}`);
    return partnerId;
});

export const fetchBestSellers = createAsyncThunk('home/fetchBestSellers', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/best_sellers/list', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const createBestSellers = createAsyncThunk('home/createBestSellers', async (partnerData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post('/admin/best_sellers/create', partnerData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updateBestSellers = createAsyncThunk('home/updateBestSellers', async (manufactureData, { rejectWithValue }) => {
    // console.log(manufactureData)
    try {
        const { id, ...dataWithoutId } = manufactureData;
        // Log the data without the ID
        // console.log('updateService', manufactureData);
        const response = await apiClient.post(`/admin/best_sellers/update/${manufactureData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const archiveBestSellers = createAsyncThunk('home/archiveBestSellers', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_delete === 1 ? 'unarchive' : 'archive';

        console.log('datataaa', partnerData)

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/best_sellers/toggle-archive/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publishBestSellers = createAsyncThunk('home/publishBestSellers', async (data, { rejectWithValue }) => {
    try {
        const action = data.is_active === 1 ? 'unpublish' : 'publish';

        const response = await jsonapiClient.post(`/admin/best_sellers/toggle-publish/${data.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const deleteBestSellers = createAsyncThunk('home/deleteBestSellers', async (id) => {
    await apiClient.delete(`/admin/best_sellers/delete/${id}`);
    return id;
});

export const fetchAwards = createAsyncThunk('home/fetchAwards', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/awards/list', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const createAwards = createAsyncThunk('home/createAwards', async (partnerData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post('/admin/awards/create', partnerData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updateAwards = createAsyncThunk('home/updateAwards', async (manufactureData, { rejectWithValue }) => {
    // console.log(manufactureData)
    try {
        const { id, ...dataWithoutId } = manufactureData;
        // Log the data without the ID
        // console.log('updateService', manufactureData);
        const response = await apiClient.post(`/admin/awards/update/${manufactureData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const archiveAwards = createAsyncThunk('home/archiveAwards', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_delete === 1 ? 'unarchive' : 'archive';

        console.log('datataaa', partnerData)

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/awards/toggle-archive/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publishAwards = createAsyncThunk('home/publishAwards', async (data, { rejectWithValue }) => {
    try {
        const action = data.is_active === 1 ? 'unpublish' : 'publish';

        const response = await jsonapiClient.post(`/admin/awards/toggle-publish/${data.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const deleteAwards = createAsyncThunk('home/deleteAwards', async (id) => {
    await apiClient.delete(`/admin/awards/delete/${id}`);
    return id;
});


export const fetchTestimonial = createAsyncThunk('home/fetchTestimonial', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/testimonial/list', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const createTestimonial = createAsyncThunk('home/createTestimonial', async (partnerData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post('/admin/testimonial/create', partnerData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updateTestimonial = createAsyncThunk('home/updateTestimonial', async (manufactureData, { rejectWithValue }) => {
    // console.log(manufactureData)
    try {
        const { id, ...dataWithoutId } = manufactureData;
        // Log the data without the ID
        // console.log('updateService', manufactureData);
        const response = await apiClient.post(`/admin/testimonial/update/${manufactureData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const archiveTestimonial = createAsyncThunk('home/archiveTestimonial', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_delete === 1 ? 'unarchive' : 'archive';

        console.log('datataaa', partnerData)

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/testimonial/toggle-archive/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publishTestimonial = createAsyncThunk('home/publishTestimonial', async (data, { rejectWithValue }) => {
    try {
        const action = data.is_active === 1 ? 'unpublish' : 'publish';

        const response = await jsonapiClient.post(`/admin/testimonial/toggle-publish/${data.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


export const deleteTestimonial = createAsyncThunk('home/deleteTestimonial', async (id) => {
    await apiClient.delete(`/admin/testimonial/delete/${id}`);
    return id;
});


// Create a slice with reducers and extra reducers
const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchBreifAbout.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBreifAbout.fulfilled, (state, action) => {
                state.loading = false;
                state.breif_info = action.payload;
            })
            .addCase(fetchBreifAbout.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createBreifAbout.pending, (state) => {
                state.loading = true;
            })
            .addCase(createBreifAbout.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(createBreifAbout.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(updateBreifAbout.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateBreifAbout.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(updateBreifAbout.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(archiveBreifAbout.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(archiveBreifAbout.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(archiveBreifAbout.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(publishBreifAbout.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(publishBreifAbout.rejected, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(publishBreifAbout.fulfilled, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(deleteBreifAbout.fulfilled, (state, action) => {
                state.res = "Info Deleted Successfullys"
            })
            .addCase(fetchAdvertising.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAdvertising.fulfilled, (state, action) => {
                state.loading = false;
                state.advertising = action.payload;
            })
            .addCase(fetchAdvertising.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createAdvertising.pending, (state) => {
                state.loading = true;
            })
            .addCase(createAdvertising.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(createAdvertising.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(updateAdvertising.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateAdvertising.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(updateAdvertising.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(publishAdvertising.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(publishAdvertising.rejected, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(publishAdvertising.fulfilled, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(deleteAdvertising.fulfilled, (state, action) => {
                state.res = "Advertisment Deleted Successfullys"
            })
            .addCase(fetchBestSellers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBestSellers.fulfilled, (state, action) => {
                state.loading = false;
                state.best_sellers = action.payload;
            })
            .addCase(fetchBestSellers.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createBestSellers.pending, (state) => {
                state.loading = true;
            })
            .addCase(createBestSellers.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(createBestSellers.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(updateBestSellers.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateBestSellers.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(updateBestSellers.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(archiveBestSellers.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(archiveBestSellers.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(archiveBestSellers.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(publishBestSellers.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(publishBestSellers.rejected, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(publishBestSellers.fulfilled, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(deleteBestSellers.fulfilled, (state, action) => {
                state.res = "Seller Deleted Successfully"
            })
            .addCase(fetchAwards.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAwards.fulfilled, (state, action) => {
                state.loading = false;
                state.awards = action.payload;
            })
            .addCase(fetchAwards.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createAwards.pending, (state) => {
                state.loading = true;
            })
            .addCase(createAwards.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(createAwards.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(updateAwards.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateAwards.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(updateAwards.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(archiveAwards.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(archiveAwards.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(archiveAwards.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(publishAwards.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(publishAwards.rejected, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(publishAwards.fulfilled, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(deleteAwards.fulfilled, (state, action) => {
                state.res = "Award Deleted Successfully"
            })
            .addCase(fetchTestimonial.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchTestimonial.fulfilled, (state, action) => {
                state.loading = false;
                state.testimonials = action.payload;
            })
            .addCase(fetchTestimonial.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createTestimonial.pending, (state) => {
                state.loading = true;
            })
            .addCase(createTestimonial.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(createTestimonial.rejected, (state, action) => {
                state.loading = false;
                state.res = action.payload;
            })
            .addCase(updateTestimonial.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateTestimonial.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(updateTestimonial.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(archiveTestimonial.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(archiveTestimonial.fulfilled, (state, action) => {
                state.loading = false;
                state.res = action.payload.message;
            })
            .addCase(archiveTestimonial.rejected, (state, action) => {
                state.loading = true;
                state.res = action.payload;
            })
            .addCase(publishTestimonial.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(publishTestimonial.rejected, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(publishTestimonial.fulfilled, (state, action) => {
                state.res = action.payload.message;
            })
            .addCase(deleteTestimonial.fulfilled, (state, action) => {
                state.res = "Testimonial Deleted Successfully"
            })
    },
});

export default homeSlice.reducer;

// Selectors to access state
export const selectAllHomeInfo = (state) => state.home;