// src/features/blog/eventsTypesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, jsonapiClient } from '../../utils/api';

//initial state
const initialState = {
    eventsTypes: [],
    loading: true,
    archiveLoading: true,
    res: null
};

// thunks for asynchronous actions
export const fetcheventsTypes = createAsyncThunk('eventsTypes/fetcheventsTypes', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/special_events_types/list', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});



export const createventsType = createAsyncThunk('eventsTypes/createeventsType', async (eventsTypeData, { rejectWithValue }) => {
    try {
        const response = await jsonapiClient.post('/admin/special_events_types/create', eventsTypeData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updateventsType = createAsyncThunk('eventsTypes/updateventsType', async (eventsTypeData, { rejectWithValue }) => {
    console.log(eventsTypeData)
    try {
        const { id, ...dataWithoutId } = eventsTypeData;
        // Log the data without the ID
        console.log('updateService', eventsTypeData);
        const response = await jsonapiClient.post(`/admin/special_events_types/update/${eventsTypeData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});



export const archiveventsType = createAsyncThunk('eventsTypes/archiveventsType', async (eventsTypeData, { rejectWithValue }) => {
    try {
        const action = eventsTypeData.is_delete === 1 ? 'unarchive' : 'archive'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/special_events_types/toggle-archive/${eventsTypeData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publisheventsType = createAsyncThunk('eventsTypes/publisheventsType', async (eventsTypeData, { rejectWithValue }) => {
    try {
        const action = eventsTypeData.is_active === 1 ? 'unpublish' : 'publish'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/special_events_types/toggle-publish/${eventsTypeData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const deleteeventsType = createAsyncThunk('eventsTypes/deleteeventsType', async (eventsTypeId) => {
    await apiClient.delete(`/admin/delete/events-types/${eventsTypeId}`);
    return eventsTypeId;
});

// Create a slice with reducers and extra reducers
const eventsTypesSlice = createSlice({
    name: 'eventsTypes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetcheventsTypes.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetcheventsTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.eventsTypes = action.payload;
            })
            .addCase(fetcheventsTypes.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createventsType.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(createventsType.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(updateventsType.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(updateventsType.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(archiveventsType.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
            })
            .addCase(archiveventsType.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
            })

            .addCase(publisheventsType.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
              })
              .addCase(publisheventsType.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
              })

            .addCase(deleteeventsType.fulfilled, (state, action) => {
                state.res = "eventsType Deleted Successfullys"
            });
    },
});

export default eventsTypesSlice.reducer;

// Selectors to access state
export const selectAlleventsTypes = (state) => state.eventsTypes;