// src/features/blog/manufacturesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, jsonapiClient } from '../../utils/api';

//initial state
const initialState = {
    manufactures: [],
    loading: true,
    archiveLoading: true,
    res: null
};

// thunks for asynchronous actions
export const fetchmanufactures = createAsyncThunk('manufactures/fetchmanufactures', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/manufacturer/list', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});



export const createManufacture = createAsyncThunk('manufactures/createManufacture', async (partnerData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post('/admin/manufacturer/create', partnerData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updateManufacture = createAsyncThunk('manufactures/updateManufacture', async (manufactureData, { rejectWithValue }) => {
    // console.log(manufactureData)
    try {
        const { id, ...dataWithoutId } = manufactureData;
        // Log the data without the ID
        // console.log('updateService', manufactureData);
        const response = await apiClient.post(`/admin/manufacturer/update/${manufactureData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});



export const archiveManufacture = createAsyncThunk('manufactures/archiveManufacture', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_delete === 1 ? 'unarchive' : 'archive'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/manufacturer/toggle-archive/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publisManufacturer = createAsyncThunk('manufactures/publishManufacturer', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_active === 1 ? 'unpublish' : 'publish'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/manufacturer/toggle-publish/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const deleteManufacturer = createAsyncThunk('manufactures/deleteManufacturer', async (partnerId) => {
    await apiClient.delete(`/admin/manufacturer/delete/${partnerId}`);
    return partnerId;
});

// Create a slice with reducers and extra reducers
const manufacturesSlice = createSlice({
    name: 'manufactures',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchmanufactures.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchmanufactures.fulfilled, (state, action) => {
                state.loading = false;
                state.manufactures = action.payload;
            })
            .addCase(fetchmanufactures.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createManufacture.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(createManufacture.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(updateManufacture.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(updateManufacture.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(archiveManufacture.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
            })
            .addCase(archiveManufacture.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
            })

            .addCase(publisManufacturer.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
              })
              .addCase(publisManufacturer.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
              })

            .addCase(deleteManufacturer.fulfilled, (state, action) => {
                state.res = "partner Deleted Successfullys"
            });
    },
});

export default manufacturesSlice.reducer;

// Selectors to access state
export const selectAllmanufactures = (state) => state.manufactures;