import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { createBreifAbout, fetchBreifAbout, updateBreifAbout } from '../../app/features/homeSlice';

const BreifInfoForm = ({ handleClose, initialValues }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        isActive: '',
    });

    const [imagePreview, setImagePreview] = useState('');
    const [fileInputKey, setFileInputKey] = useState(Date.now());

    useEffect(() => {
        if (initialValues) {
            setFormData({
                id: initialValues.id,
                title: initialValues.title || '',
                description: initialValues.description || '',
                isActive: initialValues.is_active === 1 ? 'publish' : 'unpublish' || '',
            });

            if (initialValues.imageUrl) {
                setImagePreview(initialValues.imageUrl);
            }
        } else {
            setFormData({
                title: '',
                description: '',
                isActive: '',
            });
            setImagePreview('');
        }
    }, [initialValues]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            if (files.length > 0) {
                setFormData({
                    ...formData,
                    imageUrl: files[0],
                });
                setImagePreview(URL.createObjectURL(files[0]));
            } else {
                setFormData({
                    ...formData,
                    imageUrl: null,
                });
                setImagePreview(null);
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleQuillChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            description: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const action = initialValues?.id ? updateBreifAbout(formData) : createBreifAbout(formData);
        dispatch(action)
            .unwrap()
            .then((response) => {
                setFormData({
                    title: '',
                    description: '',
                    isActive: '',
                });
                setImagePreview('');
                setFileInputKey(Date.now());
                dispatch(fetchBreifAbout({ page: 1, type: '', search_text: '' }));
                toast.success(response.message);
                handleClose();
            })
            .catch((error) => {
                toast.error(error.error || 'Failed to create service');
            });
    };


    const modules = {
        toolbar: [
            // [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image'],
            [{ 'align': [] }],
            [{ 'color': ['#cbd62e','white','black'] }, { 'background': [] }], // Add color and background options
            ['clean']                                         // Remove formatting button
        ],
    };

    const formats = [
        // 'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image',
        'align', 'color', 'background' // Add color and background formats
    ];
    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="title" className="form-label">Title</label>
                        <input
                            type="text"
                            className="form-control"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    
                </div>
            </div>
            <div className='mb-3'>
                <label htmlFor="description" className="form-label">Description</label>
                <ReactQuill
                    className='bg-white text-dark'
                    value={formData.description}
                    onChange={handleQuillChange}
                    modules={modules}
                    formats={formats}
                />
            </div>
            <div className="mb-3">
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="isActive" className="form-label">Status</label>
                        <select
                            className="form-select form-control text-white"
                            id="isActive"
                            name="isActive"
                            value={formData.isActive}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select status</option>
                            <option value="publish">Publish</option>
                            <option value="unpublish">Unpublish</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                {imagePreview && (
                    <div className='mt-2'>
                        <img
                            src={imagePreview}
                            alt="Selected"
                            style={{ width: '100px', height: 'auto' }}
                        />
                    </div>
                )}
            </div>
            <button type="submit" className="btn text-dark" style={{ backgroundColor: '#cbd62e' }}>Submit</button>
        </form>
    );
};

export default BreifInfoForm;
