// src/features/blog/eventsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, jsonapiClient } from '../../utils/api';

//initial state
const initialState = {
    events: [],
    loading: true,
    archiveLoading: true,
    res: null
};

// thunks for asynchronous actions
export const fetchEvents = createAsyncThunk('events/fetchEvents', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/special_events/list', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});



export const createEvent = createAsyncThunk('events/createEvent', async (eventData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post('/admin/special_events/create', eventData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updateEvent = createAsyncThunk('events/updateEvent', async (eventData, { rejectWithValue }) => {
    try {
        const { id, ...dataWithoutId } = eventData;
        const response = await apiClient.post(`/admin/special_events/update/${eventData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});



export const archiveEvent = createAsyncThunk('events/archiveEvent', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_delete === 1 ? 'unarchive' : 'archive'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/special_events/toggle-archive/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publishEvent = createAsyncThunk('events/publishEvent', async (eventData, { rejectWithValue }) => {
    try {
        const action = eventData.is_active === 1 ? 'unpublish' : 'publish'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/special_events/toggle-publish/${eventData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const deleteEvent = createAsyncThunk('events/deleteEvent', async (partnerId) => {
    await apiClient.delete(`/admin/special_events/delete/${partnerId}`);
    return partnerId;
});

// Create a slice with reducers and extra reducers
const eventsSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.events = action.payload;
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(archiveEvent.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
            })
            .addCase(archiveEvent.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
            })

            .addCase(publishEvent.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
              })
              .addCase(publishEvent.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
              })

            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.res = "Event Deleted Successfullys"
            });
    },
});

export default eventsSlice.reducer;

// Selectors to access state
export const selectAllEvents = (state) => state.events;