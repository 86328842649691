// src/features/blog/PartnersSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, jsonapiClient } from '../../utils/api';

//initial state
const initialState = {
    partners: [],
    loading: true,
    archiveLoading: true,
    res: null
};

// thunks for asynchronous actions
export const fetchPartners = createAsyncThunk('partners/fetchPartners', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/list/partner', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});



export const createPartner = createAsyncThunk('partners/createPartner', async (partnerData, { rejectWithValue }) => {
    try {
        const response = await apiClient.post('/admin/create/partner', partnerData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updatePartner = createAsyncThunk('partners/updatePartner', async (partnerData, { rejectWithValue }) => {
    console.log(partnerData)
    try {
        const { id, ...dataWithoutId } = partnerData;
        // Log the data without the ID
        console.log('updateService', partnerData);
        const response = await apiClient.post(`/admin/update/partner/${partnerData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});



export const archivePartner = createAsyncThunk('partners/archivePartner', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_delete === 1 ? 'unarchive' : 'archive'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/toggle-archive/partner/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publishPartner = createAsyncThunk('partners/publishPartner', async (partnerData, { rejectWithValue }) => {
    try {
        const action = partnerData.is_active === 1 ? 'unpublish' : 'publish'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/toggle-publish/partner/${partnerData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const deletePartner = createAsyncThunk('partners/deleteBlog', async (partnerId) => {
    await apiClient.delete(`/admin/delete/partner/${partnerId}`);
    return partnerId;
});

// Create a slice with reducers and extra reducers
const partnersSlice = createSlice({
    name: 'partners',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchPartners.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPartners.fulfilled, (state, action) => {
                state.loading = false;
                state.partners = action.payload;
            })
            .addCase(fetchPartners.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createPartner.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(createPartner.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(updatePartner.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(updatePartner.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(archivePartner.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
            })
            .addCase(archivePartner.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
            })

            .addCase(publishPartner.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
              })
              .addCase(publishPartner.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
              })

            .addCase(deletePartner.fulfilled, (state, action) => {
                state.res = "partner Deleted Successfullys"
            });
    },
});

export default partnersSlice.reducer;

// Selectors to access state
export const selectAllPartners = (state) => state.partners;