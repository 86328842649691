// src/app/store.js

import { configureStore } from '@reduxjs/toolkit';
import authSlice from './features/authSlice';
import partnersSlice from './features/partnersSlice';
import partnersTypesSlice from './features/partnersTypesSlice';
import eventsTypesSlice from './features/eventsTypesSlice';
import manufacturesSlice from './features/manufacturesSlice';
import eventsSlice from './features/eventsSlice';
import homeSlice from './features/homeSlice';


export const store = configureStore({
  reducer: {
    auth: authSlice,
    partners:partnersSlice,
    partnersTypes:partnersTypesSlice,
    eventsTypes:eventsTypesSlice,
    manufactures:manufacturesSlice,
    events:eventsSlice,
    home:homeSlice
  },
});