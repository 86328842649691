// src/features/blog/partnersTypesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiClient, jsonapiClient } from '../../utils/api';

//initial state
const initialState = {
    partnersTypes: [],
    loading: true,
    archiveLoading: true,
    res: null
};

// thunks for asynchronous actions
export const fetchpartnersTypes = createAsyncThunk('partnersTypes/fetchpartnersTypes', async (pageQuery, { rejectWithValue }) => {
    try {
        const response = await apiClient.get('/admin/list/partner-types', { params: pageQuery });
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }

});



export const createpartnersType = createAsyncThunk('partnersTypes/createpartnersType', async (partnersTypeData, { rejectWithValue }) => {
    try {
        const response = await jsonapiClient.post('/admin/create/partner-types', partnersTypeData);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const updatepartnersType = createAsyncThunk('partnersTypes/updatepartnersType', async (partnersTypeData, { rejectWithValue }) => {
    console.log(partnersTypeData)
    try {
        const { id, ...dataWithoutId } = partnersTypeData;
        // Log the data without the ID
        console.log('updateService', partnersTypeData);
        const response = await jsonapiClient.post(`/admin/update/partner-types/${partnersTypeData.id}`, dataWithoutId);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});



export const archivepartnersType = createAsyncThunk('partnersTypes/archivepartnersType', async (partnersTypeData, { rejectWithValue }) => {
    try {
        const action = partnersTypeData.is_delete === 1 ? 'unarchive' : 'archive'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/toggle-archive/partner-types/${partnersTypeData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const publishpartnersType = createAsyncThunk('partnersTypes/publishpartnersType', async (partnersTypeData, { rejectWithValue }) => {
    try {
        const action = partnersTypeData.is_active === 1 ? 'unpublish' : 'publish'; // Determine action based on is_delete value

        // Ensure the payload is sent as JSON
        const response = await jsonapiClient.post(`/admin/toggle-publish/partner-types/${partnersTypeData.id}`, {
            action: action
        });

        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

export const deletepartnersType = createAsyncThunk('partnersTypes/deletepartnersType', async (partnersTypeId) => {
    await apiClient.delete(`/admin/delete/partner-types/${partnersTypeId}`);
    return partnersTypeId;
});

// Create a slice with reducers and extra reducers
const partnersTypesSlice = createSlice({
    name: 'partnersTypes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchpartnersTypes.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchpartnersTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.partnersTypes = action.payload;
            })
            .addCase(fetchpartnersTypes.rejected, (state, action) => {
                state.loading = true;
                state.res = action.error.message;
            })
            .addCase(createpartnersType.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(createpartnersType.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(updatepartnersType.fulfilled, (state, action) => {
                state.res = action.payload.message; // Assuming API response has a message field
            })
            .addCase(updatepartnersType.rejected, (state, action) => {
                state.res = action.payload;
            })
            .addCase(archivepartnersType.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
            })
            .addCase(archivepartnersType.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
            })

            .addCase(publishpartnersType.rejected, (state, action) => {
                state.res = action.payload.message; // Store error message from API
              })
              .addCase(publishpartnersType.fulfilled, (state, action) => {
                state.res = action.payload.message; // Store success message from API
              })

            .addCase(deletepartnersType.fulfilled, (state, action) => {
                state.res = "partnersType Deleted Successfullys"
            });
    },
});

export default partnersTypesSlice.reducer;

// Selectors to access state
export const selectAllpartnersTypes = (state) => state.partnersTypes;