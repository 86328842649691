import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-quill/dist/quill.snow.css'; // Add styles
import { createAwards, fetchAwards, updateAwards } from '../../app/features/homeSlice';

const AwardsForm = ({ handleClose, initialValues }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        isActive: '',
        imageUrl: null
    });

    const [imagePreview, setImagePreview] = useState('');
    const [fileInputKey, setFileInputKey] = useState(Date.now());

    useEffect(() => {
        if (initialValues) {
            setFormData({
                id: initialValues.id,
                name: initialValues.name || '',
                isActive: initialValues.is_active === 1 ? 'publish' : 'unpublish' || '',
                imageUrl: null
            });

            if (initialValues.imageUrl) {
                setImagePreview(initialValues.imageUrl);
            }
        } else {
            setFormData({
                name: '',
                isActive: '',
                imageUrl: null
            });
            setImagePreview('');
        }
    }, [initialValues]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            if (files.length > 0) {
                setFormData({
                    ...formData,
                    imageUrl: files[0],
                });
                setImagePreview(URL.createObjectURL(files[0]));
            } else {
                setFormData({
                    ...formData,
                    imageUrl: null,
                });
                setImagePreview(null);
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const action = initialValues?.id ? updateAwards(formData) : createAwards(formData);
        dispatch(action)
            .unwrap()
            .then((response) => {
                setFormData({
                    name: '',
                    isActive: '',
                    imageUrl: null
                });
                setImagePreview('');
                setFileInputKey(Date.now());
                dispatch(fetchAwards({ page: 1, type: '', search_text: '' }));
                toast.success(response.message);
                handleClose();
            })
            .catch((error) => {
                toast.error(error.error || 'Failed to create award');
            });
    };


    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                   
                </div>
            </div>
            <div className="mb-3">
                <div className='row'>
                    <div className='col-6'>
                        <label htmlFor="imageUrl" className="form-label">Image</label>
                        <input
                            type="file"
                            className="form-control"
                            id="imageUrl"
                            name="imageUrl"
                            onChange={handleChange}
                            key={fileInputKey}
                        />
                    </div>
                    <div className='col-6'>
                        <label htmlFor="isActive" className="form-label">Status</label>
                        <select
                            className="form-select form-control text-white"
                            id="isActive"
                            name="isActive"
                            value={formData.isActive}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select status</option>
                            <option value="publish">Publish</option>
                            <option value="unpublish">Unpublish</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                {imagePreview && (
                    <div className='mt-2'>
                        <img
                            src={imagePreview}
                            alt="Selected"
                            style={{ width: '100px', height: 'auto' }}
                        />
                    </div>
                )}
            </div>
            <button type="submit" className="btn text-dark" style={{ backgroundColor: '#cbd62e' }}>Submit</button>
        </form>
    );
};

export default AwardsForm;
