// src/utils/api.js
import axios from 'axios';
// import { logout } from '../features/auth/authSlice'; // Assuming you have a logout action in your authSlice
// import { store } from '../app/store';
import { createBrowserHistory } from 'history';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const jsonapiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to fetch token from localStorage
const getToken = () => {
  return localStorage.getItem('token');
};

// Function to handle redirect to login
const redirectToLogin = () => {
  const history = createBrowserHistory();
  history.push('/login');
  history.go(0); // Force refresh to ensure the redirect takes effect
};

// Axios interceptor to add Authorization header before each request
apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios interceptor to add Authorization header before each request
jsonapiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios interceptor to handle responses and logout on 401
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Clear the token from localStorage
      localStorage.removeItem('token');
      
      // Dispatch the logout action
    //   store.dispatch(logout());
      
      // Redirect to the login page
      redirectToLogin();
    }
    return Promise.reject(error);
  }
);

export const loginUser = async (email, password) => {
  try {
    const credentials = { email, password };
    const response = await jsonapiClient.post('/admin/login', credentials);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// export default apiClient;