import React from 'react';

const AddModal = ({initialValues, show, handleClose, form: FormComponent }) => {

  return (
    <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{initialValues?.id ? 'Edit' : 'Add'}</h5>
            <button type="button" className="btn-close bg-secondary" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <FormComponent handleClose={handleClose} initialValues={initialValues}/>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show bg-white" style={{zIndex:-1}} onClick={handleClose}></div>
    </div>
  );
};

export default AddModal;
