import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ConfirmModal = ({ isOpen, onRequestClose, onConfirm }) => {
  return (
    <div className={`modal fade ${isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Are you sure?</h5>
            <button type="button" className="close" onClick={onRequestClose} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Do you really want to delete this Item? This process cannot be undone.
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onRequestClose}>Cancel</button>
            <button type="button" className="btn btn-danger" onClick={onConfirm}>Confirm</button>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show bg-white" style={{zIndex:-1}} onClick={onRequestClose}></div>

    </div>
  );
};

export default ConfirmModal;
