import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createInvestor, createPartner, fetchPartners, updateInvestor, updatePartner } from '../app/features/partnersSlice';
import { fetchpartnersTypes } from '../app/features/partnersTypesSlice';

const AddPartnersForm = ({ handleClose, initialValues }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        partnershipYears: '',
        typeOfPartner: '',
        isActive: '',
        partnerUrl: '',
        logo: null,
        isHome: '',
    });

    const [imagePreview, setImagePreview] = useState('');
    const [fileInputKey, setFileInputKey] = useState(Date.now()); // Unique key for file input
    const [partnersType, setPartnersType] = useState([]);

    useEffect(() => {
        dispatch(fetchpartnersTypes())
            .unwrap()
            .then((data) => {
                setPartnersType(data);
            })
            .catch(() => {
                toast.error('Failed to fetch master services');
            });
    }, [dispatch]);



    useEffect(() => {

        console.log(initialValues)
        if (initialValues) {
            setFormData({
                id: initialValues.id,
                name: initialValues.name || '',
                partnershipYears: initialValues.partnershipYears || '',
                typeOfPartner: initialValues.typeOfPartner || '',
                isActive: initialValues.is_active === 1 ? 'publish' : 'unpublish' || '',
                partnerUrl: initialValues.partnerUrl || '',
                logo: null,
                isHome: initialValues.is_home == 1 ? true : false || '',
            });

            if (initialValues.logo) {
                setImagePreview(initialValues.logo);
            }
        } else {
            setFormData({
                name: '',
                partnershipYears: '',
                typeOfPartner: '',
                isActive: '',
                partnerUrl: '',
                logo: null,
                isHome: false
            });
            setImagePreview('');
        }
    }, [initialValues]);

    const handleChange = (e) => {
        const { name, value, type, files,checked } = e.target;

        if (type === 'file') {
            if (files.length > 0) {
                // If a new file is selected, update the formData and image preview
                setFormData({
                    ...formData,
                    [name]: files[0],
                });
                setImagePreview(URL.createObjectURL(files[0]));
            } else {
                // If no file is selected, set the corresponding field to null
                setFormData({
                    ...formData,
                    [name]: null,
                });
                setImagePreview(null);
            }
        } else if (type === 'checkbox') {
            // For checkbox fields, use the checked property
            console.log('checked')
            setFormData({
                ...formData,
                [name]: checked ,
            });
        }else {
            // For other input fields, update normally
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        const action = initialValues?.id ? updatePartner(formData) : createPartner(formData);
        dispatch(action)
            .unwrap()
            .then((response) => {
                setFormData({
                    name: '',
                    partnershipYears: '',
                    typeOfPartner: '',
                    isActive: '',
                    partnerUrl: '',
                    logo: null,
                    isHome: ''
                });
                setImagePreview(''); // Clear the image preview
                setFileInputKey(Date.now()); // Reset the file input field
                dispatch(fetchPartners({ page: 1, type: '', search_text: '' }));
                toast.success(response.message);
                handleClose();
            })
            .catch((error) => {
                toast.error(error.error || 'Failed to create service');
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="name" className="form-label">Partner Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='col'>
                        <label htmlFor="partnershipYears" className="form-label">Partnership Years</label>
                        <input
                            type="number"
                            className="form-control"
                            id="partnershipYears"
                            name="partnershipYears"
                            value={formData.partnershipYears}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className='row'>
                    <div className='col-6'>
                        <label htmlFor="typeOfPartner" className="form-label">Type of Partner</label>
                        {/* <input
                            type="text"
                            className="form-control"
                            id="typeOfPartner"
                            name="typeOfPartner"
                            value={formData.typeOfPartner}
                            onChange={handleChange}
                            required
                        /> */}

                        <select
                            className="form-select form-control text-white"
                            id="typeOfPartner"
                            name="typeOfPartner"
                            value={formData.typeOfPartner}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select partner type</option>
                            {partnersType?.data?.map((partner) => (
                                <option key={partner.id} value={partner.id}>{partner.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className='col-6'>
                        <label htmlFor="isActive" className="form-label">Status</label>
                        <select
                            className="form-select form-control text-white"
                            id="isActive"
                            name="isActive"
                            value={formData.isActive}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select status</option>
                            <option value="publish">Publish</option>
                            <option value="unpublish">Unpublish</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <label htmlFor="partnerUrl" className="form-label">Partner Website Link</label>
                <input
                    type="text"
                    className="form-control"
                    id="partnerUrl"
                    name="partnerUrl"
                    value={formData.partnerUrl}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="mb-3">
                <label htmlFor="logo" className="form-label">Logo</label>
                <input
                    type="file"
                    className="form-control"
                    id="logo"
                    name="logo"
                    onChange={handleChange}
                    key={fileInputKey} // Use key to reset input field
                />
                {imagePreview && (
                    <div className='mt-2'>
                        <img
                            src={imagePreview}
                            alt="Selected"
                            style={{ width: '100px', height: 'auto' }}
                        />
                    </div>
                )}
            </div>

            <div className="mb-3">

                <div className="form-switch ">
                    <input
                    type="checkbox" 
                    id="isHome" 
                    name="isHome" 
                    className="form-check-input"
                    checked={formData.isHome}  // Check if isHome is 'yes' for checked state
                    onChange={handleChange}

                    />
                    <label for="isHome" className="form-label">Set to Home</label>

                </div>
            </div>

            <button type="submit" className="btn text-dark" style={{ backgroundColor: '#cbd62e' }}>Submit</button>
        </form>
    );
};

export default AddPartnersForm;
