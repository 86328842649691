import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { archivePartner, deletePartner, fetchPartners, publishPartner } from '../../app/features/partnersSlice';
import ConfirmModal from '../../components/ConfirmModal';
import { faEye, faPenToSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArchive, faPenClip, faPlusCircle, faRotateBack, faRotateRight, faTrashCanArrowUp, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import AddModal from '../../components/Addmodal';
import AddPartnersForm from '../../forms/AddPartnersForm';
import 'react-toastify/dist/ReactToastify.css';
import { fetchpartnersTypes } from '../../app/features/partnersTypesSlice';
import { archiveManufacture, deleteManufacturer, fetchmanufactures, publisManufacturer, selectAllmanufactures } from '../../app/features/manufacturesSlice';
import ManufacturesForm from '../../forms/ManufacturesForm';
import { archiveAdvertising, archiveBreifAbout, deleteAdvertising, deleteBreifAbout, fetchAdvertising, fetchBreifAbout, publishAdvertising, publishBreifAbout, selectAllHomeInfo } from '../../app/features/homeSlice';
import BreifInfoForm from '../../forms/LandingPage/BreifInfoForm';
import AdvertisingForm from '../../forms/LandingPage/AdvertisingForm';


export default function Advertising() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const homeinfo = useSelector(selectAllHomeInfo);
    console.log('homeinfo',homeinfo)

    const [showModal, setShowModal] = useState(false);
    const [initialValue, setInitialValue] = useState(null);


    const handleCloseAddModal = () => setShowModal(false);

    const handleShowModal = (row) => {
        setShowModal(true);
        setInitialValue(row);
    };

    const [pageQuery, setPageQuery] = useState({
        page: 1,
        type: "",
        search_text: ""
    });

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedInfoId, setSelectedInfoId] = useState(null);

    const handleOpenDeleteModal = (id) => {
        setSelectedInfoId(id);
        setIsDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setSelectedInfoId(null);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteAdvertising(selectedInfoId))
            .unwrap()
            .then(() => {
                toast.success('Info deleted successfully');
                dispatch(fetchAdvertising(pageQuery));
                handleCloseDeleteModal();
            })
            .catch(() => {
                toast.error('Failed to delete Manufacturer');
            });
    };

    const handleArchive = (data) => {
        dispatch(archiveAdvertising(data))
            .unwrap()
            .then((response) => {
                dispatch(fetchAdvertising(pageQuery));
                toast.success(response.message);
            })
            .catch((error) => {
                toast.error(error.error);
            });
    };

    const handleStatusChange = (data) => {
        dispatch(publishAdvertising(data))
            .unwrap()
            .then((response) => {
                dispatch(fetchAdvertising(pageQuery));
                toast.success(response.message);
            })
            .catch((error) => {
                toast.error(error.error || 'Status not updated');
            });
    };

    useEffect(() => {
        dispatch(fetchAdvertising(pageQuery));
    }, [pageQuery, dispatch]);

    const handleSearchChange = debounce((searchText) => {
        setPageQuery(prevState => ({
            ...prevState,
            search_text: searchText
        }));
    }, 300);

    const handleTypeChange = debounce((type) => {
        setPageQuery(prevState => ({
            ...prevState,
            type: type
        }));
    }, 100);



    const columns = [
        {
            name: 'Title',
            selector: row => row.title1,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Sub Title',
            selector: row => row.title2,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Publish Status',
            selector: row => (
                <>
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={row.is_active}
                            onChange={() => handleStatusChange(row)}
                        />
                        <span className="slider"></span>
                    </label>
                </>
            ),
            width: '200px'
        },
        {
            name: 'Action',
            selector: row => (
                <div className="d-flex justify-content-between">
                    <button className="btn btn-primary btn-sm m-1"
                        onClick={() => handleShowModal(row)}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                    {row.is_delete === 1 ? (
                        <>
                            <button className="btn btn-secondary btn-sm m-1" onClick={() => handleArchive(row)}>
                                <FontAwesomeIcon icon={faRotateBack} />
                            </button>
                            <button className="btn btn-danger btn-sm m-1" onClick={() => handleOpenDeleteModal(row.id)}>
                                <FontAwesomeIcon icon={faTrashCanArrowUp} />
                            </button>
                        </>
                    ) : (
                        <button className="btn btn-warning btn-sm m-1" onClick={() => handleArchive(row)}>
                            <FontAwesomeIcon icon={faArchive} />
                        </button>
                    )}
                </div>
            ),
        }
    ];

    const customStyles = {
        header: {
            style: {
                height: '106px',
            },
        },
        headRow: {
            style: {
                fontSize: '14px'
            },
        },
        headCells: {
            style: {
                height: '50px',
            },
        },
        cells: {
            style: {
                height: 'auto',
            },
        },
        table: {
            style: {
                border: '1px solid black',
            },
        }
    };

    createTheme('solarized', {
        text: {
            primary: '#ced2ea',
            secondary: '#ced2ea',
        },
        background: {
            default: '#191c24',
        },
        context: {
            background: '#cb4b16',
            text: '#ced2ea',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
    }, 'dark');

    return (
        <>
            <ToastContainer />

            <div className='p-4'>
                <h2>Advertising</h2>

                <div className='d-flex justify-content-between pb-2'>
                    <div className='w-50 d-flex'>
                        <input
                            type='search'
                            className='form-control'
                            placeholder='Search...'
                            onChange={(e) => handleSearchChange(e.target.value)}
                        />
                        <select
                            className='form-control ms-3 text-white'
                            name='ARCHIVED'
                            onChange={(e) => handleTypeChange(e.target.value)}
                        >
                            <option value='PUBLISHED'>Published</option>
                            <option value='ARCHIVED'>Archived</option>
                            <option value='UNPUBLISHED'>Unpublished</option>
                        </select>
                    </div>
                    <div>
                        <button className="btn text-dark pt-2 pb-2 p-2" style={{ backgroundColor: '#cbd62e' }} onClick={() => handleShowModal(null)}>Add Info <FontAwesomeIcon icon={faPlusCircle} /></button>
                    </div>
                </div>
                <DataTable
                    className=''
                    columns={columns}
                    data={homeinfo?.advertising?.data}
                    customStyles={customStyles}
                    pagination
                    paginationPerPage={homeinfo?.advertising?.pagination?.pageSize}
                    paginationRowsPerPageOptions={[10, 20, 30]}
                    dense
                    paginationServer
                    paginationTotalRows={homeinfo?.advertising?.pagination?.totalItems}
                    onChangePage={(currentPage) => {
                        setPageQuery((prevState) => ({
                            ...prevState,
                            page: currentPage
                        }));
                    }}
                    theme='solarized'
                // fixedHeader
                // fixedHeaderScrollHeight="100vh"
                />
            </div>

            <AddModal
                show={showModal}
                handleClose={handleCloseAddModal}
                form={AdvertisingForm}
                initialValues={initialValue}
            />

            <ConfirmModal
                isOpen={isDeleteModalOpen}
                onRequestClose={handleCloseDeleteModal}
                onConfirm={handleConfirmDelete}
                contentLabel="Confirm Deletion"
            />
        </>
    );
}
