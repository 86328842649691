import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createpartnersType, fetchpartnersTypes, updatepartnersType } from '../app/features/partnersTypesSlice';
import { createventsType, fetcheventsTypes, updateventsType } from '../app/features/eventsTypesSlice';

const EventsTypesForm = ({ handleClose, initialValues }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        isActive: '',
    });

    const [imagePreview, setImagePreview] = useState('');
    useEffect(() => {
        if (initialValues) {
            setFormData({
                id: initialValues.id,
                name: initialValues.name || '',
                isActive: initialValues.is_active === 1 ? 'publish' : 'unpublish' || '',
            });

            if (initialValues.logo) {
                setImagePreview(initialValues.logo);
            }
        } else {
            setFormData({
                name: '', 
                isActive: '',
            });
            setImagePreview('');
        }
    }, [initialValues]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
    
        if (type === 'file') {
            if (files.length > 0) {
                setFormData({
                    ...formData,
                    [name]: files[0],
                });
                setImagePreview(URL.createObjectURL(files[0]));
            } else {
                setFormData({
                    ...formData,
                    [name]: null,
                });
                setImagePreview(null);
            }
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };
    

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        const action = initialValues?.id ? updateventsType(formData) : createventsType(formData);
        dispatch(action)
            .unwrap()
            .then((response) => {
                setFormData({
                    name: '',
                    isActive: '',
                });
                setImagePreview(''); 
                dispatch(fetcheventsTypes({ page: 1, type: '', search_text: '' }));
                toast.success(response.message);
                handleClose();
            })
            .catch((error) => {
                toast.error(error.error || 'Failed to create service');
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <div className='row'>
                    <div className='col'>
                        <label htmlFor="name" className="form-label">Event Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='col-6'>
                        <label htmlFor="isActive" className="form-label">Status</label>
                        <select
                            className="form-select form-control text-white"
                            id="isActive"
                            name="isActive"
                            value={formData.isActive}
                            onChange={handleChange}
                            required
                        >
                            <option value="" disabled>Select status</option>
                            <option value="publish">Publish</option>
                            <option value="unpublish">Unpublish</option>
                        </select>
                    </div>
                </div>
            </div>

            <button type="submit" className="btn text-dark" style={{backgroundColor:'#cbd62e'}}>Submit</button>
        </form>
    );
};

export default EventsTypesForm;
